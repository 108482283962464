import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import InsidePageBanner from '../Component/InsidePageBanner'
import heroLine from '../assets/images/hero-line.png';
import article1 from '../assets/images/article-1.jpg';
import article2 from '../assets/images/article-2.jpg';
import article3 from '../assets/images/article-3.jpg';
import BlogsCard from '../Component/BlogsCard';
import Footer from '../Layout/Footer';
import { Link } from 'react-router-dom';
import SEO from '../SeoComponent/Seo';
import { getapi } from '../Api/Api';
import { BaseURL } from '../Api/BaseUrl';
import moment from 'moment';

function AllArticles() {
    const [articles, setarticles] = useState([])

    const handleget = async () => {
        let res = await getapi('published-blogs')
        setarticles(res.data.data)
    }

    useEffect(() => {
        handleget()
    }, [])

    // const articles = [
    //     {
    //         id: 1,
    //         title: 'The Clear Difference Between Cold Brew & Iced Coffee',
    //         date: '25 ',
    //         month: "JUN",
    //         imgSrc: article1,
    //         description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
    //         link: '#',
    //     },
    //     {
    //         id: 2,
    //         title: 'Your Small Business Success From Marketing',
    //         date: '25 ',
    //         month: "JUN",
    //         imgSrc: article2,
    //         description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
    //         link: '#',
    //     },
    //     {
    //         id: 3,
    //         title: 'The Start-Up Ultimate Guide Make Wordpress Journal.',
    //         date: '25 ',
    //         month: "JUN",
    //         imgSrc: article3,
    //         description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
    //         link: '#',
    //     },
    //     {
    //         id: 4,
    //         title: 'The Start-Up Ultimate Guide Make Wordpress Journal.',
    //         date: '25 ',
    //         month: "JUN",
    //         imgSrc: article3,
    //         description: 'DNP Installations can carry out all maintenance on phone and data and phone points in...',
    //         link: '#',
    //     },
    // ];
    return (
        <>
            <SEO title="All Articles" description="Welcome to our Articles page." />
            <Header />
            <InsidePageBanner title="Blog" />
            <section class="article-page-area pt-70 pb-100">
                <div class="container">
                    <div class="row justify-content-center">
                        {articles.map((item) => {
                            return (
                                <>
                                    <div class="col-lg-4 col-md-6 col-sm-9">
                                        <div class="article-2-item mt-30">
                                            <div class="article-thumb">
                                                <img loading="lazy" src={`${BaseURL}${item.image}`} style={{ height: "200px" }} alt={item?.alt_image} />
                                            </div>
                                            <div class="article-content">
                                                {item.blog_type && <span className='blogtype'>{item.blog_type}</span>}
                                                <div class="date">
                                                    <div class="item">
                                                        <h5 class="title">{moment(item.publish_date).format("MMMM-DD-YYYY")}</h5>
                                                        {/* <span>{item.month}</span> */}

                                                    </div>

                                                </div>
                                                <h3 class="title"><a href="#">{item.title}.</a></h3>
                                                <Link to={`/article-detail/${item.url}`}>
                                                    Learn more
                                                    <span class="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.196" height="12.663" viewBox="0 0 10.196 12.663">
                                                            <path data-name="Path 18155" d="M32.324,974.539,28.2,978.448a.731.731,0,0,0-.052.947.678.678,0,0,0,.948,0l3.027-2.864v9.346a.652.652,0,1,0,1.3,0v-9.346l3.027,2.864a.664.664,0,0,0,.948,0,.714.714,0,0,0-.052-.947l-4.126-3.909A.661.661,0,0,0,32.324,974.539Z" transform="translate(-27.676 -974.112)" fill="#fff" stroke="#fff" stroke-width="0.5"></path>
                                                        </svg>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default AllArticles
