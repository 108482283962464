import React, { useEffect, useState } from 'react'



import portfolio1 from '../assets/images/About-us-3.png'





import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Link } from 'react-router-dom'
import { getapi } from '../Api/Api'
import { BaseURL } from '../Api/BaseUrl'






function OurWork() {
    const [data, setdata] = useState([])
    const [activeTab, setActiveTab] = useState('');
    const [contentdata, setcontentdata] = useState([])
    const handleget = async () => {
        let res = await getapi('portfolio')
        console.log(res)
        setdata(res?.data?.data)
        setActiveTab(res?.data?.data?.[0]?.name)
    }
    useEffect(() => {
        handleget()
    }, [])

    useEffect(() => {
        if (activeTab) {
            handlecontent()

        }
    }, [activeTab])

    const handlecontent = async () => {
        let res = await getapi(`subport?name=${activeTab}`)

        setcontentdata(res?.data?.data)
    }


    return (
        <>


            <section class="portfolio-2-area" id="portfolio">
                <div class="container">
                    <div class="row ">
                        <div class="col-lg-12">
                            <div class="section-title-3 text-center">
                                <span>Our work</span>
                                <h3 class="title">Our Portfolio</h3>
                            </div>
                            <div class="project-menu">

                                <ul className="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
                                    {data.map((item) => (
                                        <li className="nav-item" role="presentation" key={item.name}>
                                            <button
                                                className={`nav-link ${activeTab === item.name ? 'active' : ''}`}
                                                id={`pills-${item.name.toLowerCase()}-tab`}
                                                data-bs-toggle="pill"
                                                data-bs-target={`#pills-${item.name.toLowerCase()}`}
                                                type="button"
                                                role="tab"
                                                aria-controls={`pills-${item.name.toLowerCase()}`}
                                                aria-selected={activeTab === item.name}
                                                onClick={() => setActiveTab(item.name)}
                                            >
                                                {item.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <div className="row">

                                            {contentdata.length > 0 ? <ResponsiveMasonry
                                                columnsCountBreakPoints={{ 350: 1, 768: 3, 992: 3 }}
                                            >
                                                <Masonry gutter="1rem">
                                                    {contentdata?.map((item, index) => {

                                                        return (
                                                            <div class="portfolio-2-item mt-30">
                                                                <img
                                                                    src={`${BaseURL}${item?.image?.[0]?.img}`}
                                                                    className="img-fluid"
                                                                    alt={item.title}
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = portfolio1;
                                                                    }}
                                                                    style={{height:"381px"}}
                                                                />
                                                                <div class="portfolio-overlay">
                                                                    <h4 class="title">{item.title}</h4>
                                                                    <span>{item.subtitle}</span>
                                                                </div>
                                                                <div class="portfolio-overlay-2 text-center">
                                                                    <h4 class="title">{item.boxtitle}</h4>
                                                                    <span>{item.subtitle}</span><br />
                                                                    <Link to={`/portfolio-detail/${item.url}`}>
                                                                        Show more
                                                                        <span class="icon">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.196" height="12.663" viewBox="0 0 10.196 12.663">
                                                                                <path data-name="Path 18155" d="M32.324,974.539,28.2,978.448a.731.731,0,0,0-.052.947.678.678,0,0,0,.948,0l3.027-2.864v9.346a.652.652,0,1,0,1.3,0v-9.346l3.027,2.864a.664.664,0,0,0,.948,0,.714.714,0,0,0-.052-.947l-4.126-3.909A.661.661,0,0,0,32.324,974.539Z" transform="translate(-27.676 -974.112)" fill="#fff" stroke="#fff" stroke-width="0.5" />
                                                                            </svg>
                                                                        </span>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </Masonry>
                                            </ResponsiveMasonry> : <h3 className='mt-5'>Data Not Found</h3>}
                                        </div>

                                    </div>

                                </div>
                                {/* <div class="row">
                                    <div class="col-lg-12">
                                        <div class="portfolio-btn text-center mt-40">
                                            <a class="main-btn main-btn-3" href="#">ALL Projects</a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </>
    )
}

export default OurWork

