import React from 'react'
import InsidePageBanner from '../Component/InsidePageBanner'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import heroLine from '../assets/images/hero-line.png';
import SEO from '../SeoComponent/Seo'
import ContactUsModal from '../Component/ContactUsModal'

function Contact() {
    const venturedata = [
        {
            title: "Happening Ads ",
            address: "K-20, 2nd Floor , Sector 18, Noida-201301 (U.P)",
            number: "9999453999",
            mail: "Info@happeningads.com"

        },

    ]
    return (
        <>
            <SEO title="Contact - Haappeningads" description="Contact us today to discuss how we can help you to achive your business goals with our adertising experties." keywords="" />

            <Header />
            {/* <InsidePageBanner title="Contact" /> */}

            <section className="contact-us-area contact-us-page" style={{ marginTop: "94px" }}>
                <div className="section__bg"></div>
                <div className="container">
                    <div className="row mb-5">



                    </div>
                    <div className="row justify-content-center gy-3">
                        <div className="col-md-6">
                            <div className="row gy-3">
                                {venturedata.map((item) => {
                                    return (
                                        <>
                                            <div className="col-md-12">
                                                <div className="bg-white h-100 shadow p-3 rounded-3 venturebox">
                                                    <h3 className='mb-0'>{item.title}</h3>
                                                    <div className="my-2" style={{ border: "1px solid #ddd" }}></div>
                                                    <div className="row align-items-center">
                                                        <div className="col-md-1 col-1 pe-0">
                                                            <i class="fa-solid fa-phone "></i>

                                                        </div>
                                                        <div className="col-md-11 col-11 ps-0">
                                                            <h6 className='mb-0'> {item.number}</h6>

                                                        </div>
                                                        <div className="col-md-1 col-1 pe-0">
                                                            <i class="fa-solid fa-envelope-open-text "></i>

                                                        </div>
                                                        <div className="col-md-11 col-11 ps-0">
                                                            <h6 className='mb-0'> {item.mail}</h6>

                                                        </div>

                                                        <div className="col-md-1 col-1 pe-0">
                                                            <i class="fal fa-map-marker-alt  "></i>

                                                        </div>
                                                        <div className="col-md-11 col-11 ps-0">
                                                            <h6 className='mb-0'> {item.address}</h6>

                                                        </div>


                                                    </div>


                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                                <div className="col-md-12">
                                    <iframe className='w-100 rounded-3' width="600" height="300" style={{ border: "0" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.9835822429154!2d77.31701902741216!3d28.570255712848486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce44f4d1b2ae1%3A0x8f6e0889e47a1e8e!2sk%2C%2020%2C%20Pocket%20L%2C%20Sector%2018%2C%20Noida%2C%20Uttar%20Pradesh%20201301!5e0!3m2!1sen!2sin!4v1724326502216!5m2!1sen!2sin" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="contact-us-box">
                                <h3 className="title">Get in Touch</h3>

                                {/* <form action="#">
                                    <div className="row no-gutters">
                                        <div className="col-lg-6">
                                            <div className="input-box mt-10">
                                                <input type="text" placeholder="Your name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="input-box mt-10">
                                                <input type="email" placeholder="Email address" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="input-box mt-10">
                                                <input type="text" placeholder="Number" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="input-box mt-10">
                                                <input type="text" placeholder="Location" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="input-box mt-10">
                                                <textarea name="#" id="#" cols="30" rows="10" placeholder="Write message"></textarea>
                                            </div>
                                            <ul className="checkbox_common checkbox_style5">
                                                <li>
                                                    <input type="checkbox" name="checkbox5" id="checkbox13" />
                                                    <label htmlFor="checkbox13"><span></span>I agree to the data protection regulations</label>
                                                </li>
                                            </ul>
                                            <div className="input-box">
                                                <button className="main-btn" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form> */}
                                <ContactUsModal check={false} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{ background: "#000" }}>


            </section>
            <Footer />


        </>
    )
}

export default Contact
