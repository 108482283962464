// export const BaseURL = 'https://localhost:9011/'

export const BaseURL = "https://haappeningads.com:9011/"

// export const BaseURL = 'https://62.72.30.137:9011/'
export const currenturl = window.location.hostname




