import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords }) => {
    const canonicalUrl = window.location.hostname === 'haappeningads.com'
        ? `https://www.haappeningads.com${window.location.pathname}`
        : `https://www.haappeningads.com${window.location.pathname}`;
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:type" content="website" />
                <meta name="keywords" content={keywords} /> {/* Adding keywords meta tag */}
                <link rel="canonical" href={canonicalUrl} /> {/* Add canonical tag */}

                {/* Other meta tags can be added as needed */}
            </Helmet>

            {/* JSON-LD Structured Data */}
            <script type="application/ld+json">
                {`
{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "${title}",
  "description": "${description}",
  "keywords": "${keywords}",
  "url": "${canonicalUrl}"
}
        `}
            </script>
        </>
    );
};

export default SEO;
