import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import { truncateText } from './WordLimit';
import logo from "../assets/images/logo.png"
import { BaseURL } from '../Api/BaseUrl';
import moment from 'moment';
import 'animate.css';

function MainSerCard(props) {
    const navigate = useNavigate()

    const settings = {
        dots: false,

        infinite: true,
        speed: 500,

        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 3000, // Set autoplay speed to 3000 milliseconds
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <>
            <div className="col-md-2">
                <Tilt options={{ max: 25, scale: 1.05, speed: 400 }}>
                    <div className="cards h-100 ">
                        <div className="">
                            <Slider {...settings}>
                                {props.data && props.data.map((item) => {
                                    return (
                                        <motion.div
                                            className='p-3 card h-100'
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => navigate(`/service-detail/${props.url}`, { state: props.mainid })}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.5 }}
                                        >
                                            <div className="">
                                                <h5 className={`blinkingbtn card-title px-3 py-2 rounded-3 text-center`}>
                                                    {props.maintitle}
                                                </h5>
                                            </div>
                                            <div className={item.image ? '' : 'bg-secondary h-200'}>
                                                <img
                                                    src={`${BaseURL}${item.image}` || logo}
                                                    className="d-block w-100"
                                                    alt="Slide 1"
                                                    onError={(e) => { e.target.src = logo }}
                                                />
                                            </div>
                                            <div className="serviceoverlay">
                                                <h6 style={{ fontSize: "22px" }} className='mb-1'>
                                                    {item.title}
                                                </h6>
                                            </div>
                                            {/* <div className="content-box pt-3 pb-0">
                                                <h6 style={{ fontSize: "22px" }} className='mb-1'>
                                                    {item.title}
                                                </h6>
                                                <p style={{ color: "grey" }} className='card-content'>
                                                    {truncateText(item.shortdetail, 12)}
                                                </p>
                                            </div> */}
                                        </motion.div>

                                    );
                                })}
                            </Slider>
                        </div>
                    </div>
                </Tilt>
            </div>
        </>
    );
}

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', right: "26px", zIndex: "99", top: "200px" }}
            onClick={onClick}
        />
    );
}

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', left: "26px", zIndex: "99", top: "200px" }}
            onClick={onClick}
        />
    );
}

export default MainSerCard;


