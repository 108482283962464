import React from 'react';

import logo from '../assets/images/logo.png'; // Replace with your actual logo path
import { Link, useLocation, useNavigate } from 'react-router-dom';
import gamslogo from "../assets/images/gamslogo.png"
import whatsapp from "../assets/images/whatsapp.png"

const Footer = () => {

    const location = useLocation()

    const otherpage = location.pathname == '/'

    const navigate = useNavigate()

    const subMenuItems = [
        // {
        //     id: 1,
        //     title: 'Home',
        //     link: '/'
        // },
        // {
        //     id: 2,
        //     title: 'About',
        //     link: `${otherpage ? "#about" : "/"}`
        // },
        // {
        //     id: 3,
        //     title: 'Service',
        //     link: `${otherpage ? "#services" : "/"}`
        // },
        // {
        //     id: 4,
        //     title: 'Portfolio',
        //     link: `${otherpage ? "#portfolio" : "/"}`
        // },

        {
            id: 5,
            title: 'Career',
            link: '/career'
        },
        {
            id: 6,
            title: 'Blogs',
            link: '/allarticles'
        },
        {
            id: 7,
            title: 'Faq',
            link: '/about'
        },
        {
            id: 8,
            title: 'Contact',
            link: '/contact'
        },
        {
            id: 9,
            title: 'Privacy Policy',
            link: '/privacy-policy'
        }
    ];
    return (
        <>

            <footer className="footer-area pt-0 pb-3">
                <div className="container">
                    <div className="row">

                    </div>
                    <div className="footer-item pt-3">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-about mt-30">
                                    <h4 className="title">Your Story, Perfectly Served</h4>
                                    {/* <div className="mb-4">
                                        <img loading="lazy" src={logo} className='bg-white shadow p-3 rounded-3' style={{ width: "300px" }} alt="" />
                                    </div> */}

                                    <p className='mt-2'>
                                        We are not only storytellers but also story builders. Our work with creativity goes beyond the transaction; we enliven the essence of advertising.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-6 col-sm-6">
                                <div className="footer-list mt-30 ml-95 ">
                                    <h4 className="title">Quick links</h4>
                                    <ul>
                                        {subMenuItems.map((item) => {
                                            return (
                                                <>
                                                    <li>
                                                        <Link to={item.link}>
                                                            {item.title}
                                                        </Link>
                                                    </li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer-list mt-30">
                                <h4 className="title">Categories</h4>
                                <ul>
                                    <li><a href="#">About us</a></li>
                                    <li><a href="#">Terms and conditions</a></li>
                                    <li><a href="#">Privacy policy</a></li>
                                    <li><a href="#">News</a></li>
                                    <li><a href="#">Contact us</a></li>
                                </ul>
                            </div>
                        </div> */}
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-info mt-30">
                                    <h3 className="title">Address</h3>
                                    <ul>
                                        <li className='text-white'><i class="fal fa-map-marker-alt"></i> K-20,  Sector 18,  Noida-201301 (U.P)</li>
                                        <li><a href="tel:9999453999" className='text-white'><i class="fa-solid fa-phone "></i> 9999453999</a> </li>
                                        <li style={{ cursor: "pointer" }} ><a className='text-white' href="mailto:Info@happeningads.com" ><i class="fal fa-envelope"></i> Info@happeningads.com </a></li>
                                    </ul>
                                    <div className="footer-social">
                                        <ul className='d-flex gap-3'>
                                            <li><a href="https://www.facebook.com/Haappeningads" target='_blank'><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/haappeningads/" target='_blank'><i class="fab fa-linkedin"></i></a></li>
                                            <li><a href="https://x.com/haappeningads" target='_blank'><i class="fab fa-twitter"></i></a></li>

                                            <li><a href="https://www.instagram.com/haappeningads/" target='_blank'> <i class="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright">
                                <p>Copyright @ 2020. Design by Seative.Digital</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </footer>
            <section style={{ boxShadow: "0 0 1px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-top pb-0 py-2 d-block d-flex  justify-content-center align-items-center">
                                <div className="footer-logo text-center ">
                                    <Link to="/"><img loading="lazy" src={gamslogo} alt="Logo" /></Link>
                                </div>
                                {/* <div className="footer-social">
                                    <ul>
                                        <li><a href="https://www.facebook.com/Happeningads" target='_blank'><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/happeningads" target='_blank'><i class="fab fa-linkedin"></i></a></li>
                                        <li><a href="https://x.com/Happening_ads" target='_blank'><i class="fab fa-twitter"></i></a></li>
                                       
                                        <li><a href="https://www.instagram.com/happeningads/" target='_blank'> <i class="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a
                href="https://wa.me/+919999453999"
                target="_blank"
                rel="noopener noreferrer"
                class="position-fixed bottom-0 end-0 mb-4 me-4 text-white p-3 rounded-circle bg-white shadow"
                aria-label="Contact us on WhatsApp"
            >
                <img loading="lazy" src={whatsapp} className='img-fluid' alt="" style={{ zIndex: "9999999999999", width: "40px" }} />
            </a>
            {/* {!otherpage && <div className="position-fixed top-0 start-4 mb-4 ms-4 backbtn  ">
                <button className="btn px-3 rounded-3 " onClick={(e) => navigate(-1)}><i class="fa-solid fa-angle-left"></i> </button>
            </div>} */}
        </>
    );
};

export default Footer;
