import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import SEO from '../SeoComponent/Seo';
import InsidePageBanner from '../Component/InsidePageBanner';

function Terms() {
    return (
        <>
            <SEO title="Terms & Conditions - Haappeningads" description="We are consumer privacy centric company. Your privacy is our priority." keywords="" />

            <Header />
            <InsidePageBanner title="Terms & Conditions" />
            <section className='my-3'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='mt-5 text-center'>Terms & Conditions</h2>
                            <p>I hereby authorize and give consent to Gahmar Admark Solutions Pvt. Ltd. to send me, either through itself or through any third party service provider, from time to time various information / alerts/SMS/ other messages or calls or commercial communication, and other services on the aforesaid listed telephone numbers, whether these numbers are registered with National Do Not Call Registry/ listed in National Customer Preference Register or not. I also confirm that by sending any of such messages / calls, I will not hold ASBL its third party service provider liable / institute complaint under the Telecom Commercial Communications Customer Preference (TRAI) Regulations, 2010 or such other applicable regulations including any amendment thereof, as may be applicable from time to time. It will be auto renewed every month and if you want to stop this service please write an email to info@happeningads.com before the due date and call our support number <a href="tel:09999453999">09999453999</a>  also once the amount is debited from the account it will not be refunded.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Terms;
