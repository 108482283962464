import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import InsidePageBanner from '../Component/InsidePageBanner'
import ServiceCard from '../Component/ServiceCard'
import heroLine from '../assets/images/hero-line.png';
import { useNavigate } from 'react-router-dom'
import SEO from '../SeoComponent/Seo'
import { getapi } from '../Api/Api'

import Preloader from '../Component/PreLoder'

function Carrer() {
    const navigate = useNavigate()
    const [loading, setloading] = useState(false)

    const [jobs, setjobs] = useState([])
    const data = [
        {
            title: "Flexible Working Hours"
        },
        { title: "Prefer Skills over experience" },
        {
            title: "Cooperative Co-Workers"
        },
        {
            title: "Transparency in management"
        },
        {
            title: " Competitive Salaries"
        },
        {
            title: "Learn & Grow Opportunity"
        }
    ]

    // const jobs = [
    //     {
    //         title: "Node Js Backend Devloper",
    //         link: "nodejs",
    //         type: "Remote"
    //     },

    //     {
    //         title: "Slot Game Developer",
    //         type: "Office",
    //         link: "gamedevloper",
    //     },
    //     {
    //         title: "Flutter Developer",
    //         type: "Office",
    //         link: "flutter",
    //     },

    //     {
    //         title: "Business Coordinator",
    //         type: "Remote",
    //         link: "business",
    //     },

    // ]

    const handleapply = (e, item) => {
        e.preventDefault()
        navigate(`/job-detail/${item.url}`)
    }


    const handleget = async () => {
        let res = await getapi('jobs')
        setloading(true)
        if (res?.data?.error == 0) {

            setjobs(res?.data?.data)
            setloading(false)
        } else {
            setloading(false)
        }
    }

    useEffect(() => {
        handleget()

    }, [])
    return (
        <>
            {loading && <Preloader />}
            <SEO title="Career - Haappeningads" description="Grow you carrer with Happeingads. We are hiring for multiple positions." keywords="" />

            <Header />
            <InsidePageBanner title="Career Page" />
            <section className='mb-5'>
                <div className="container">
                    <div className="row mb-30 gy-3">
                        <div className="col-md-12">
                            <div className="section-title text-center mt-4">
                                <img loading="lazy" src={heroLine} alt="Hero Line" />
                                <h3 className="title ">	Career Path and Development</h3>
                            </div>
                        </div>
                        {data.map((item) => {
                            return (
                                <>
                                    <div class="col-lg-4 col-md-6 col-sm-8">
                                        <div class={`service-item  h-100 animated wow text-center fadeInUp ${item.class}`}
                                            data-wow-duration="1000ms" data-wow-delay="0ms">
                                            <div class="icon">

                                                {/* <img loading="lazy" src={`${BaseURL}${item.image}`} className='img-fluid rounded-4' style={{ width: "100%" }} alt="" /> */}
                                            </div>
                                            <h3 class="title mt-3">{item.title}</h3>
                                            {item.paragraph && <p>{item.paragraph}</p>}
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className='bg-dark py-3 pb-5 mt-3'>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-md-12">
                            <div className="section-title text-center">
                                <img loading="lazy" src={heroLine} alt="Hero Line" />
                                <h3 className="title text-white">Job Openings </h3>

                            </div>
                        </div>
                        {jobs.map((item) => {
                            return (
                                <>
                                    <div className="col-md-6">
                                        <div className="bg-white shadow p-3 rounded-3">
                                            <div className="d-flex gap-2 flex-wrap">
                                                <h3>{item.title}</h3>
                                                {item.officetype == "Office" ? <span className='badge applybadge bg-primary' style={{ fontSize: "14px" }}><i class="fas fa-briefcase"></i> {item.officetype}</span> : <span className='badge applybadge' style={{ fontSize: "14px" }}><i class="fas fa-home"></i> {item.officetype}</span>}
                                            </div>
                                            <h6>Required Experience : 2-3 Years</h6>
                                            <div className="text-end">
                                                <button className='btn  main-btn applynow shadow-none' onClick={(e) => handleapply(e, item)}>Apply Now</button>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )
                        })}

                    </div>
                </div>
            </section>
            <section style={{ background: "#000" }}>

                <iframe className='w-100' width="600" height="300" style={{ border: "0" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.9835822429154!2d77.31701902741216!3d28.570255712848486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce44f4d1b2ae1%3A0x8f6e0889e47a1e8e!2sk%2C%2020%2C%20Pocket%20L%2C%20Sector%2018%2C%20Noida%2C%20Uttar%20Pradesh%20201301!5e0!3m2!1sen!2sin!4v1724326502216!5m2!1sen!2sin" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
            <Footer />
        </>
    )
}

export default Carrer
