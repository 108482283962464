import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Banner from '../HomeSection/Banner'
import Card from '../Component/Card'
import AboutUs from '../HomeSection/About'
import OurService from '../HomeSection/OurService'
import OurWork from '../HomeSection/OurWork'
import Testimonial from '../HomeSection/Testimonial'
import Overview from '../HomeSection/Overview'
import Team from '../HomeSection/Team'
import ArticleSection from '../HomeSection/Blogs'
import Footer from '../Layout/Footer'
import SEO from '../SeoComponent/Seo'
import MainSerCard from '../Component/MainSerCard'
import buisness from "../assets/images/BusinessStrategy.gif"
import digital from "../assets/images/DigitalMarketing.gif"
import Marketing from "../assets/images/MarketingStratagy.gif"
import promotion from "../assets/images/promotion.gif"
import photo from "../assets/images/photo.gif"
import ContactUsModal from '../Component/ContactUsModal'
import { getapi } from '../Api/Api'
import FormContainer from '../Component/FormComp'






function Home() {

    const data = [
        {
            icon: buisness,
            title: "Business Strategy",
            para: "Your Partner in Delivering Excellence"
        },
        {
            icon: digital,
            title: "Promotion Design",
            para: "Elevate Your Brand with Promotions that Captivate"
        },
        {
            icon: Marketing,
            title: "Marketing Stratagy",
            para: "Transforming Vision into Results that Impact"
        },
        {
            icon: promotion,
            title: "Digital Marketing",
            para: "Boost Your Brand in the Digital Space"
        },
        {
            icon: photo,
            title: "Photo Shoot",
            para: "Showcasing Your Brand through Impactful Imagery",
            // para2: "Showcasing the Strength of Your Operations through Images Frame by Frame"
        },
    ]


    // const servicedata = [
    //     {
    //         img1: "https://images.unsplash.com/photo-1488372759477-a7f4aa078cb6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //         img2: " https://images.unsplash.com/photo-1595147389795-37094173bfd8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGltYWdlfGVufDB8fDB8fHww",
    //         img3: "https://images.unsplash.com/photo-1575936123452-b67c3203c357?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //         title: "Brand Activation",
    //         para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet libero saepe quibusdam tempora soluta beatae, itaque voluptatum accusamus, sed esse deserunt dolores ut temporibus fugiat aspernatur magni pariatur, iste labore."
    //     },
    //     {
    //         img1: "https://images.unsplash.com/photo-1488372759477-a7f4aa078cb6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //         img2: " https://images.unsplash.com/photo-1595147389795-37094173bfd8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGltYWdlfGVufDB8fDB8fHww",
    //         img3: "https://images.unsplash.com/photo-1575936123452-b67c3203c357?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //         title: "Visa Activate",
    //         para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet libero saepe quibusdam tempora soluta beatae, itaque voluptatum accusamus, sed esse deserunt dolores ut temporibus fugiat aspernatur magni pariatur, iste labore."
    //     },
    //     {
    //         img1: "https://images.unsplash.com/photo-1488372759477-a7f4aa078cb6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //         img2: " https://images.unsplash.com/photo-1595147389795-37094173bfd8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGltYWdlfGVufDB8fDB8fHww",
    //         img3: "https://images.unsplash.com/photo-1575936123452-b67c3203c357?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //         title: "Main activate",
    //         para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet libero saepe quibusdam tempora soluta beatae, itaque voluptatum accusamus, sed esse deserunt dolores ut temporibus fugiat aspernatur magni pariatur, iste labore."
    //     },
    // ]


    return (
        <>


            <SEO title="Haappeningads - Connecting Brands With Consumer
" description="We are an advertising agency with expertise in both online and offline advertising with proven track record.
" keywords="Haappeningads, Advertising Agency
" />

            <Header />
            <Banner />
            {/* <section className='sub-item-area '>
                <div className="container">
                    <div className="d-flex gap-3">
                        <Card data={data} />
                    </div>
                </div>
            </section> */}
            <AboutUs />

            <OurService />
            <OurWork />
            <Testimonial />
            {/* <Overview /> */}
            {/* <Team /> */}
            <ArticleSection />
            <Footer />
            {/* <FormContainer /> */}


        </>
    )
}

export default Home
